<template>
  <section class="blog">
    <div class="inner">
<!--      <h2>All posts</h2>-->
      <Post v-for="(post, i) in posts" :key="i" :post="post"></Post>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Post from "../components/Post";
import api from "../api/api";

export default defineComponent({
  name: "Blog",
  components: {Post},
  data() {
    return {
      posts: []
    }
  },
  mounted() {
    api.getData('get/b').then(r => {
      // console.log('get data: ', r)
      if (!r.data.error) {
        this.posts = r.data.posts || []
      }
    }).catch(e => {
      console.error(e)
    })
  }
})
</script>

<style lang="scss" src="../assets/scss/blog.scss"></style>
